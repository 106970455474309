<template>
  <div class="container-fluid padding-top-layout">
    <div class="wrap">
      <div class="wrap__block">
        <div class="wrap__block__header wrap__header">
          <div class="wrap__block__header__content">
            <h1 class="wrap-title">{{ $t("b_list_all.title_product_emission_list") }}</h1>
            <div class="helper-icon">
              <img
                :src="getSettingIcon('helper-icon.svg')"
                @mouseover="changeTooltipImage($event)"
                @mouseleave="changeTooltipImage($event, 'mouseleave')"
                alt=""
              />
              <div
                v-if="showTooltip"
                class="helper-icon tooltip"
              >{{ $t("b_list_all.tooltip_description") }}</div>
            </div>
          </div>
        </div>
      </div>
      <div
        class="category-table main-table custom-table new-register view-table"
        :class="[isFullScreen && 'full-screen', isExpand ? 'expanded' : 'no-expanded']"
      >
        <data-table
          :data-source="listData"
          :grid-columns="gridColumns"
          :init-grid="initializeGrid"
          :rowFocus="1"
          :showFocus="false"
          :allowAddNew="false"
          :isShowToolbar="false"
          :isEmptySource="isEmptySource"
          :labelEmpty="labelEmpty"
          :isResizeCustome="false"
          @changeFullScreen="onChangeFullScreen"
          @onHandlerSelecteFilterColumn="onHandlerSelecteFilterColumn"
          class="product-list-table"
        />
      </div>
      <comment-log-popup :dialog="commentPopup" :contents="commentHistoryList" @close="commentPopup = false" />
      <history-popup :dialog="historyPopup" :historyLogList="historyLogList" @close="closePopup" />
    </div>
    <notification-popup :dialog="dialogNotification" :message="messageNotification" @submit="dialogNotification = false" />
    <notification-popup :dialog="showPopupDurationSuccess" :message="message" @submit="showPopupDurationSuccess = false"/>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
import { CollectionView } from "@mescius/wijmo";
import DataTable from "@/components/category/data-table";
import { ROUTES } from "@/router/constants";
import { KEYS_CODE } from "@/constants/keyboard";
import * as wjGrid from "@mescius/wijmo.grid";
import { CellMaker } from "@mescius/wijmo.grid.cellmaker";
import CommonButton from "@/components/utils/button.vue";
import HistoryPopup from "@/components/dialogs/history-popup.vue";
import CommentLogPopup from '@/components/dialogs/log-confirm-popup';
import NotificationPopup from '@/components/dialogs/notification-popup';
import { getProductData,getProductListLogHistory} from "@/api/product";
import { getStatusNameById, getColorStatusById } from "@/utils/status";
import { formatDateTimeDataTable } from '@/utils/datetimeFormat';
import moment from "moment";
import { getDataLayer, getScopeName, getCategoryName, getPeriodFromMonthYear } from "@/utils/registerData";
import { REGISTER_PULLDOWN, LIST_PULLDOWN } from '@/constants/registerData';
import { ORGANIZATIONAL_DATA } from '@/constants/registerData.js';
import { $_helper_isNumberType, formatValue, formatBigNumber } from '@/concerns/newRegisterData/wijmo.helper';
import {addThoundSandComma, addThoundSandCommaWithTwoDigit} from '@/utils/convertNumber';
import { getStartMonth } from '@/api/duration';
import { getWidthOfDetailButton, getMinWidthOrganization } from '@/utils/multiLanguage'
import { getWidthByText } from '@/utils/getWidthByText'
import { getWidthByTextContent, setMinMaxSizeColumns } from '@/utils/calcTextWidth';
export default {
  name: "ListTemplates",
  data() {
    return {
      items           : [
        {
          text    : this.$t('b_list_all.hyperlink_home'),
          disabled: false,
          href    : ROUTES.PRODUCTS
        },
        {
          text    : this.$t('b_list_all.label_product_emission_list'),
          disabled: false,
          href    : `${ROUTES.PRODUCTS}/${ROUTES.LIST_PRODUCTS}`
        }
      ],
      message: this.$t("list_reduction.description_message_duration_success"),
      duration        : "",
      flex            : null,
      listEmissionData: [],
      listData        : null,
      durationId      : null,
      undoStack       : null,
      canUndo         : false,
      canRedo         : false,
      commentPopup    : false,
      historyPopup    : false,
      gridColumns     : [],
      showTooltip     : false,
      historyLogList     : [],
      commentHistoryList: [],
      dialogNotification: false,
      messageNotification:  this.$t("b_list_all.message_notification"),
      isRedirectDuration: false,
      disabledTemplateButton: false,
      showPopupDurationSuccess: false,
      showRegisterPulldown: false,
      listLayer :[],
      isEmptySource: true,
      labelEmpty: this.$t("b_list_all.label_empty"),
      selectedFilterColumn: null,
      startMonth: null,
    };
  },
  components: {
    DataTable,
    CommonButton,
    CommentLogPopup,
    HistoryPopup,
    NotificationPopup
  },
  async mounted() {
    await this.updateBreadCrumb(this.items);
    this.actionUpdateIsLoadingTable(true)
    try {
      await getStartMonth(this.$store.state.userData.contractor).then(res => {
        this.startMonth = res.data.start_month;
      })
      await this.getTemplateData();
      this.actionUpdateIsLoadingTable(false)
    } catch (error) {
      this.actionUpdateIsLoadingTable(false)
    }
    this.showPopupDurationSuccess = this.isChangedDurationSuccess;
    this.actionUpdateIsChangedDurationSuccess(false)
  },
  computed: {
    ...mapState("commonApp", ["loadMore", "isFilter", "isExpand"]),
    ...mapState("registerData", ["isFullScreen",'isChangedDurationSuccess']),
    ...mapState("userData", ["currentUser"]),
    isAdmin() {
      return this.currentUser?.user?.role_id === 3;
    },
    registerPulldown() {
      return Object.values(REGISTER_PULLDOWN);
    },
    listPullDown() {
      return LIST_PULLDOWN
    },
    listPullDownSp() {
      return LIST_PULLDOWN.slice(0, 2)
    }
  },
  methods : {
    ...mapActions("commonApp", ["updateBreadCrumb", "actionUpdateIsLoadingTable"]),
    ...mapActions("registerData", ["updateDataScope", "updateDataCategory", "updateDataMonth", "updateDuration", "updateListDuration","actionUpdateIsChangedDurationSuccess"]),
    ...mapActions("newRegisterData", ["updateIsApproved"]),

    getSettingIcon(image) {
      if (image) {
        return require(`@/assets/icons/${image}`);
      }
      return "";
    },
    redirectToTemplate() {
      return this.$router.push({ path: "/emissions/" + ROUTES.LIST_EMISSION_TEMPLATES }).catch(() => {
      });
    },
    initializeGrid(flexgrid) {
      this.flex = flexgrid;

      document.addEventListener("keydown", (event) => {
        if (
          (event.metaKey || event.ctrlKey) &&
          [KEYS_CODE.DOWN_ARROW, KEYS_CODE.UP_ARROW, KEYS_CODE.LEFT_ARROW, KEYS_CODE.RIGHT_ARROW].includes(
            event.keyCode
          )
        ) {
          event.preventDefault();
        }
      });

      flexgrid.hostElement.addEventListener(
        "keydown",
        (event) => {
          if (event.metaKey || event.ctrlKey) {
            if (event.keyCode === KEYS_CODE.DOWN_ARROW) {
              const currentSelection = flexgrid.selection;
              const cellRange        = new wjGrid.CellRange(flexgrid.rows.length - 1, currentSelection.col);
              flexgrid.selection     = cellRange;

              // re-select after add more
              setTimeout(() => {
                flexgrid.selection = cellRange;
              }, 200);
            } else if (event.keyCode === KEYS_CODE.UP_ARROW) {
              const currentSelection = flexgrid.selection;
              flexgrid.selection     = new wjGrid.CellRange(0, currentSelection.col);
            } else if (event.keyCode === KEYS_CODE.RIGHT_ARROW) {
              const currentSelection = flexgrid.selection;
              flexgrid.selection     = new wjGrid.CellRange(currentSelection.row, flexgrid.columns.length - 1);
            } else if (event.keyCode === KEYS_CODE.LEFT_ARROW) {
              const currentSelection = flexgrid.selection;
              flexgrid.selection     = new wjGrid.CellRange(currentSelection.row, 1);
            }
          }
        },
        false
      );
      flexgrid.hostElement.addEventListener('mouseover',(e)=>{
        if(e.target.parentElement.classList.contains('comment-icon')){
          e.target.src = require(`@/assets/icons/comment_active.svg`);
        }
      });
      flexgrid.hostElement.addEventListener('mouseout',(e)=>{
        if(e.target.parentElement.classList.contains('comment-icon')){
        e.target.src = require(`@/assets/icons/comment.svg`);
        }
      });
      this.flex.onSelectionChanged(null);
    },
    async onHistoryClicking(rowData) {
      if (!rowData.item?.id) return;
      this.workflowDataId  = rowData.item?.id;
      await this.handleGetHistoryChanged(rowData.item?.id);
      this.historyPopup    = true;
      this.dialogCancelBtn = true;
    },
    async handleGetHistoryChanged(workflowDataId) {
      const payload = {
        id: workflowDataId
      }
      const listHistoryChanged = await getProductListLogHistory(payload);
      this.historyLogList = listHistoryChanged.data.map(listHistoryChanged => {
          return {
            ...listHistoryChanged,
            date: formatDateTimeDataTable(listHistoryChanged.updated_at),
            name: listHistoryChanged.content,
            status: getStatusNameById(listHistoryChanged.status),
            color: getColorStatusById(listHistoryChanged.status)
          }
        })
    },
    changeTooltipImage(event, type) {
      if (type === "mouseleave") {
        event.target.src                        = this.getSettingIcon("helper-icon.svg");
        event.target.parentElement.style.cursor = "default";
        this.showTooltip                        = false;
      } else {
        event.target.src                        = this.getSettingIcon("helper-icon_active.svg");
        event.target.parentElement.style.cursor = "pointer";
        this.showTooltip                        = true;
      }
    },
    async getTemplateData() {
      getProductData().then((res) => {
        res.data.forEach((item) => {
          const product = item.product?.product_master
          const branchData = product?.branch_id ? product?.branch : product
          this.listEmissionData.push({
            id               : item.id,
            data_id      : item.id,
            status           : item.status === null ? "" :getStatusNameById(item.status),
            title            : item.title,
            comment          : "",
            scope            :getScopeName(parseInt(item.scope)),
            category         :getCategoryName(parseInt(item.scope), parseInt(item.category)),
            month_year_registration: item.product?.year !== null &&  item.product?.month !== null ? `${item.product.year}/${String(item.product.month).padStart(2, '0')}` : '',
            last_modified    : formatDateTimeDataTable(item.updated_at),
            iconComment      : item.comment_count > 0 ? require(`@/assets/icons/comment.svg`) : '',
            date_submit      : formatDateTimeDataTable(item.submitted_at),
            date_approval    : formatDateTimeDataTable(item.approved_at),
            layer1           : getDataLayer(item.scope, item.category, item.pattern_id)?.method,
            layer2           : getDataLayer(item.scope, item.category, item.pattern_id)?.sub_method,
            log_history      : this.$t('b_list_all.button_log_display'),
            approved_by: item.approved_by,
            duration_id: item.duration_id,
            scope_id: parseInt(item.scope),
            category_id: parseInt(item.category),
            month: item.month,
            pattern_id: item.pattern_id,
            products_name    : item.product?.product_master?.name,
            product_code    : item.product?.product_master?.code,
            organizational_division : branchData?.organizational_division !== null ? ORGANIZATIONAL_DATA[branchData?.organizational_division] : null,
            company_name     : branchData?.company_name  || null,
            business_name: branchData?.business_name || null,
            country: branchData?.country || null,
            layer_3 : branchData?.layer_3 || null,
            layer_4 : branchData?.layer_4 || null,
            layer_5 : branchData?.layer_5 || null,
            layer_6 : branchData?.layer_6 || null,
            registration_period : item.product?.year !== null &&  item.product?.month !== null ? getPeriodFromMonthYear(moment, this.startMonth, item.product.month, item.product.year) : '',
            emission_total_product: item.product?.emission_total_product ? this.formatValueNumberFullData(item.product?.emission_total_product) : "0.00",
            emission_per_product : item.product?.emission_per_product ? this.formatValueNumberFullData(item.product?.emission_per_product) : "-",
            submitter: item?.submitter?.name,
            approver_info: item.approved_by ? item.approver?.name : item.selected_approved?.name,
          });
        });
        this.listLayer = res.layer
        this.defineTable();
      })
    },
    defineTable() {
      this.gridColumns       = this.getGridColumns();
      const formatItems = ['emission_total_product', 'emission_per_product'];
      this.listData          = new CollectionView([...this.listEmissionData], {
        sortComparer: (a, b) => {
          if (!formatItems.includes(this.selectedFilterColumn)) return null;
          if ($_helper_isNumberType(a) && $_helper_isNumberType(b)) {
            const numA = Number(formatValue(a));
            const numB = Number(formatValue(b));
            return numA - numB;
          }
          return null;
        }
      });
      this.flex.columnGroups = this.getGridColumns();
      this.listData.currentItem = null;
      setMinMaxSizeColumns(this.flex, this.listEmissionData);
      this.$nextTick(() => {
        this.scrollToTop();
      });
      this.isEmptySource = this.listData?.items?.length <= 0;
    },
    getGridColumns() {
      const layerIndex = {
        1: 'business_name',
        2: 'country',
        3: 'layer_3',
        4: 'layer_4',
        5: 'layer_5',
        6: 'layer_6',
      }
      const layers = this.listLayer.map((item) => {
        return {
          header: item.layer_name,
          visible: true,
          binding: layerIndex[item.layer_index],
          maxWidth: 980,
          minWidth: getWidthByTextContent(item.layer_name) + 10,
          allowSorting: false,
          isRequired: false,
          wordWrap: true,
          isReadOnly  : true,
          multiLine   : true,
        };
      });
      return [
        {
          header      : "#",
          binding     : "id",
          allowSorting: false,
          isReadOnly  : true,
          visible     : false
        },
        {
          header      : " ",
          binding     : "data_id",
          minWidth    : getWidthOfDetailButton(),
          maxWidth    : getWidthOfDetailButton(),
          allowSorting: false,
          isRequired  : false,
          cssClassAll : "btn-db table-id hide-filter",
          cellTemplate: CellMaker.makeButton({
            text : `<button class=\"btn_custom\">${this.$t("b_list_all.button_edit")}</button>`,
            click: (event, context) => this.onIdClicking(context)
          })
        },
        {
          header      : this.$t('b_list_all.table_products_name'),
          binding     : "products_name",
          minWidth    : getWidthByTextContent(this.$t('b_list_all.table_products_name')),
          maxWidth    : 980,
          allowSorting: false,
          isReadOnly  : true,
          multiLine   : true,
          wordWrap    : true,
        },
        {
          header      : this.$t('b_list_all.table_product_code'),
          binding     : "product_code",
          minWidth    : getWidthByTextContent(this.$t('b_list_all.table_product_code')),
          maxWidth    : 980,
          allowSorting: false,
          isRequired  : false,
          isReadOnly  : true,
          multiLine   : true,
          wordWrap    : true,
        },
        {
          header : this.$t('b_list_all.table_organizational_information'),
          align  : "center",
          collapseTo: 'organizational_division',
          columns: [
            {
              header      : this.$t('b_list_all.table_organizational_division'),
              binding     : "organizational_division",
              minWidth    : getMinWidthOrganization(),
              maxWidth    : 400,
              allowSorting: false,
              isRequired  : false,
              isReadOnly  : true,
              multiLine   : true,
              wordWrap    : true,
            },
            {
              header      : this.$t('b_list_all.table_company_name'),
              binding     : "company_name",
              minWidth    : getWidthByTextContent(this.$t('b_list_all.table_company_name')) + 5,
              maxWidth    : 400,
              allowSorting: false,
              isRequired  : false,
              isReadOnly  : true,
              wordWrap    : true,
            },
            ...layers
          ]
        },
        {
          header      : this.$t('b_list_all.table_registration_period'),
          binding     : "registration_period",
          minWidth    : getWidthByTextContent(this.$t('b_list_all.table_registration_period')),
          maxWidth    : 200,
          allowSorting: false,
          isRequired  : false,
          isReadOnly  : true,
          multiLine   : true,
          wordWrap    : true,
        },
        {
          header      : this.$t('b_list_all.table_month_year_registration'),
          binding     : "month_year_registration",
          minWidth    : getWidthByTextContent(this.$t('b_list_all.table_month_year_registration')),
          maxWidth    : 200,
          allowSorting: false,
          isRequired  : false,
          isReadOnly  : true,
          multiLine   : true,
          wordWrap    : true,
        },
        {
          header : this.$t('b_list_all.table_exhaust_amount_(t-co2)'),
          align  : "center",
          cssClassAll: 'emission',
          columns: [
            {
              header      : this.$t('b_list_all.table_emission_total_product'),
              binding     : "emission_total_product",
              minWidth    : getWidthByTextContent(this.$t('b_list_all.table_emission_total_product')) + 5,
              maxWidth    : 400,
              allowSorting: false,
              isRequired  : false,
              isReadOnly  : true,
              wordWrap    : true,
              cssClassAll: 'emission color-text',
              align: 'right',
            },
            {
              header      : this.$t('b_list_all.table_emission_per_product'),
              binding     : "emission_per_product",
              minWidth    : getWidthByTextContent(this.$t('b_list_all.table_emission_per_product')) + 5,
              maxWidth    : 400,
              allowSorting: false,
              isRequired  : false,
              isReadOnly  : true,
              wordWrap    : true,
              cssClassAll: 'emission color-text',
              align: 'right',
            },            
          ]
        },
        {
          header      : this.$t('b_list_all.table_submitter'),
          binding     : "submitter",
          minWidth    : getWidthByTextContent(this.$t('b_list_all.table_submitter')),
          maxWidth    : 200,
          allowSorting: false,
          isRequired  : false,
          isReadOnly  : true,
          multiLine   : true,
          wordWrap    : true,
        },
        {
          header      : this.$t('b_list_all.table_date_submit'),
          binding     : "date_submit",
          minWidth    : getWidthByTextContent(this.$t('b_list_all.table_date_submit')),
          maxWidth    : 200,
          allowSorting: false,
          isReadOnly  : true,
          cssClassAll : "",
          multiLine   : true,
          wordWrap    : true,
        },
        {
          header      : this.$t('b_list_all.table_approver_info'),
          binding     : "approver_info",
          minWidth    : getWidthByTextContent(this.$t('b_list_all.table_approver_info')),
          maxWidth    : 200,
          allowSorting: false,
          isRequired  : false,
          isReadOnly  : true,
          multiLine   : true,
          wordWrap    : true,
        },
        {
          header      : this.$t('b_list_all.table_date_approval'),
          binding     : "date_approval",
          minWidth    : getWidthByTextContent(this.$t('b_list_all.table_date_approval')) + 5,
          maxWidth    : 300,
          allowSorting: false,
          isReadOnly  : true,
          cssClassAll : "",
          multiLine   : true,
          wordWrap    : true,
        },
        {
          header      : this.$t('b_list_all.table_log_history'),
          binding     : "log_history",
          minWidth    : getWidthByText(this.$t('b_list_all.table_log_history')),
          width: '*',
          allowSorting: false,
          cssClass    : "btn-db",
          cssClassAll : "cell_comment-confirm hide-filter",
          multiLine   : true,
          wordWrap    : true,
          cellTemplate: (ctx, el) => {
            const button =  CellMaker.makeButton({
              text : `<button>${ctx?.item?.text? ctx?.item?.text : this.$t("b_register_product.button_choice") }</button>`,
              click: (e, ctx) => this.onHistoryClicking(ctx)
            });
            return button (ctx, el);
          }
        },
      ];
    },
    scrollToTop() {
      if (!this.flexGrid) {
        return;
      }

      let rc                       = this.flexGrid.cells.getCellBoundingRect(0, 0, true);
      this.flexGrid.scrollPosition = new wjcCore.Point(this.flexGrid.scrollPosition.x, -rc.top);
    },
    onChangeFullScreen(isFullScreen) {
      if (isFullScreen) {
        this.scrollToTop();
      } else {
        this.initialView();
      }
    },
    closePopup() {
      this.commentPopup = false;
      this.historyPopup = false;
    },
    async onIdClicking(rowData) {
      if (!rowData.item?.id) return;
      this.$router.push({ path: `/products/register?type=view&id=${rowData.item.id}` });
    },
    showRegisterPulldownEvent() {
      if (this.duration === '') {
        this.dialogNotification = true;
        return;
      }
      this.showRegisterPulldown = !this.showRegisterPulldown;
    },
    redirectHandler(item) {
      this.showRegisterPulldown = false;
      switch (item) {
        case REGISTER_PULLDOWN.CREATE_NEW:
          this.$router.push({ path: "/products/" + ROUTES.PRODUCTS_NEW_REGISTER }).catch(() => {});
          break;
        case REGISTER_PULLDOWN.CREATE_FROM_TEMPLATE:
          this.$router.push({ path: "/products/" + ROUTES.LIST_EMISSION_TEMPLATES }).catch(() => {});
          break;
        default:
          this.$router.push({ path: "/products/" + ROUTES.LIST_MENU_EMISSION + '/?csv=true' }).catch(() => {});
          break;
      }
    },
    formatValueNumber(number) {
      return addThoundSandComma(number)
      // return formatValue(number).replace(/(?<!\..*)(\d)(?=(?:\d{3})+(?:\.|$))/g, '$1,')
    },
    formatValueNumberFullData(number) {
      return addThoundSandCommaWithTwoDigit(formatBigNumber(number, 50).replace(/[^0-9\.\-]+/g, ''), '', false, true);
    },
    onHandlerSelecteFilterColumn(column) {
      this.selectedFilterColumn = column;
    },
  }
};
</script>
<style scoped lang="scss">
@import '@/views/emissions/NewRegister/styles/index.scss';
.template-detail-header {
  .wj-btn {
    display: none;
  }
}

.wj-flexgrid .wj-cell-maker {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  color: $monoBlack;
  // font-family: 'Source Han Sans JP';
}

.wj-cell {
  .wj-cell-maker {
    background: none;
    justify-content: start;
  }

  .wj-cell-maker:hover {
    background: none;
    color: $monoBlack;
  }
}

.duration-wrapper {
  .duration-text {
    color: $monoBlack;
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
    letter-spacing: 0.03em;
    margin-bottom: 8px;
  }

  .calculation-page {
    display: flex;
    align-items: center;
    background-color: $bgLight;
    padding: 15px;
    border-radius: 4px;
    height: 56px;

    div {
      margin-right: 20px;

      .duration-content {
        color: $monoBlack;
        font-weight: 700;
        font-size: 11px;
        line-height: 26px;
        letter-spacing: 0.03em;
        padding: 5px 0px 0px 0px;
      }

      .duration-text {
        color: $goldMid;
        font-weight: 700;
        font-size: 14px;
        letter-spacing: 0.03em;
      }
    }
  }
}

.template-detail-header {
  .wj-cell-maker {
    margin-top: 2px;
    width: 26px;
    height: 20px;
    font-style: normal;
    // font-family: 'Source Han Sans JP';
    font-size: 13px;
    font-weight: 500;
    line-height: 20px;
    color: $goldMid;
    text-align: center;
    background: none;
    border: 1px solid $goldLight;
  }

  .wj-cell-maker:hover {
    color: $monoWhite;
    background: #0072a3;
  }
}
.hide-filter {
  .wj-btn {
    display: none;
  }
}
.custom-table {
  @media (max-width: $tablet) {
    padding-left: 20px !important;
  }
}
.wrap {
  width: 100%;
  height: 100%;

  &__status {
    margin-bottom: 48px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 20px;
    padding-right: 0;

    @media (max-width: 768px) {
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      gap: 16px;
      padding-right: 0px;
    }

    &__item {
      display: none;

      &__wrap {
        margin-left: 12px;

        p {
          color: $monoBlack;
          margin: 0;
          font-style: normal;
          font-weight: 400;
          font-size: 12px;
          line-height: 22px;
          letter-spacing: 0.05em;

          &:nth-child(1) {
            font-style: normal;
            font-weight: 700;
            font-size: 11px;
            line-height: 18px;
            letter-spacing: 0.03em;
          }
        }
      }
    }

    .duration-wrapper {
      width: 100%;

      .duration {
        .calculation-page {
          justify-content: space-between;
        }
      }
    }
  }

  &__header {
    margin-bottom: 48px;
    margin-top: 40px;

    @media (max-width: $tablet) {
      margin-left: 0px;
    }

    &--p1 {
      max-width: 700px;
    }

    p {
      margin: 0px;
      font-weight: 400;
      font-size: 22px;
      line-height: 36px;
      letter-spacing: 0.05em;
      color: $goldMid;
      @media (max-width: 768px) {
        font-size: 22px;
        line-height: 28px;
      }
    }

    p:last-child {
      padding-top: 48px;
      color: $monoBlack !important;
      font-style: normal;
      font-weight: 400;
      font-size: 15px;
      line-height: 28px;
      letter-spacing: 0.05em;
    }
  }

  &__block {
    display: flex;
    flex-direction: column;
    padding: 0 0 40px;
    @media (max-width: 768px) {
      padding-left: 0px;
      flex-direction: column;
    }

    &__header {
      flex: 1 0;
      padding-bottom: 58px;

      &__content {
        display: flex;
        flex-direction: row;
        align-items: center;
      }

      span {
        margin: 0 16px 0 0;
        font-weight: 400;
        font-size: 22px;
        line-height: 36px;
        letter-spacing: 0.05em;
        color: $goldMid;
        @media (max-width: 768px) {
          font-size: 22px;
          line-height: 28px;
          margin-right: 8px;
        }
      }
    }

    .wrap__status,
    .wrap__header {
      margin-bottom: 0px;
    }

    .wrap__status__item {
      span {
        margin-left: 10px;
      }
    }
  }
}
.return {
  &__button-responsive {
    width: calc(100% + 40px);
    display: flex;
    flex-direction: column;
    background: $bgMid;
    box-shadow: 0px -3.13px 2.86674px rgba(160, 181, 186, 0.165596), 0px -1.24px 1.13351px rgba(160, 181, 186, 0.24);
    padding: 16px 20px;
    position: relative;
    top: 80px;
    margin-left: -20px;
    .item-responsive {
      width: 100%;
      margin: 0 auto;
      .common-btn {
        width: 100%;
        .common-btn-text {
          margin-left: 10px;
        }
        .addregistericon_active {
          display: none;
        }
        .addregistericon_disabled {
          display: none;
        }
        // &:hover {
        //   .v-btn__content {
        //     .addregistericon {
        //       display: none !important;
        //     }
        //     .addregistericon_active {
        //       display: block !important;
        //     }
        //     .addregistericon_disabled {
        //       display: none;
        //     }
        //   } 
        // }
        // &:active {
        //   .addregistericon {
        //     display: block;
        //   }
        //   .addregistericon_active {
        //     display: none;
        //   }
        //   .addregistericon_disabled {
        //     display: none;
        //   }
        // }
      }
      .register-button {
        &:hover {
          .v-btn__content {
            .addregistericon {
              display: none !important;
            }
            .addregistericon_active {
              display: block !important;
            }
            .addregistericon_disabled {
              display: none;
            }
          }    
        }
      }
    }
  }
}

.register-btn {
  position: relative;
  .register-pulldown {
    position: absolute;
    width: 230px;
    max-height: 160px;
    background: $monoOffWhite;
    margin-top: 2px;
    box-shadow: 0px 36px 33px rgba(160, 181, 186, 0.0744044), 0px 13.1406px 12.0455px rgba(160, 181, 186, 0.10649), 0px 6.37951px 5.84789px rgba(160, 181, 186, 0.13351), 0px 3.12736px 2.86674px rgba(160, 181, 186, 0.165596), 0px 1.23656px 1.13351px rgba(160, 181, 186, 0.24);
    z-index: 99;
    .item-pulldown {
      display: flex;
      align-items: center;
      height: 40px;
      padding: 7px 0px 9px 16px;
      border: 1px solid rgba(42, 42, 48, 0.1);
      color: $monoBlack;
      border-bottom: 0;
      cursor: pointer;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
      font-family: "Source Han Sans";
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 24px;
      letter-spacing: 0.03em;
      margin-left: unset !important;
      &:hover {
        background: $bgLight;
      }
    }
    .pulldown-item {
      display: flex;
      flex-direction: row;
      align-items: center;
      padding: 7px 16px 9px;
      gap: 8px;
      width: 230px;
      height: 40px;
      border-bottom: 1px solid rgba(42, 42, 48, 0.1);
      cursor: pointer;
      &:last-child {
        border-bottom: unset;
      }
      &:hover {
        background: $bgLight;
      }
      .pulldown-text {
        font-size: 14px;
        line-height: 24px;
        letter-spacing: 0.03em;
        color: $monoBlack;
        white-space: nowrap;
      }
      &.disabled {
        pointer-events: none;
        cursor: default;
        background: $monoLight !important;
        .pulldown-text {
          color: $monoMid;
          font-weight: 500;
        }
      }
    }
  }
}
@include desktop {
  .wrap {
    &__header {
      margin-top: unset;
      margin-left: 40px;
    }

    &__status {
      padding-right: 40px;

      &__item {
        display: block;
      }
    }

    &__block {
      flex-direction: row;

      &__header {
        padding-bottom: 0;
      }
    }

    .wrap__block__header__content {
      display: flex;

      span {
        margin: 8px 10px 0px 0px;
      }

      // .helper-icon {
      //   margin: 16px 10px 0px 0px;
      // }
    }
    .wrap__status__item {
      .addregistericon_active {
        display: none;
      }
      .addregistericon_disabled {
        display: none;
      }
      .register-button {
        &:hover {
          .v-btn__content {
            .addregistericon {
              display: none;
            }
            .addregistericon_active {
              display: block;
            }
            .addregistericon_disabled {
              display: none;
            }
          }
        }
      }
    }
  }
}

.return__listSubmitted {
  margin-right: 40px !important;

  .return__header {
    background: $monoOffWhite;
    box-shadow: 0px 36px 33px rgba(160, 181, 186, 0.0744044), 0px 13.1406px 12.0455px rgba(160, 181, 186, 0.10649),
    0px 6.37951px 5.84789px rgba(160, 181, 186, 0.13351), 0px 3.12736px 2.86674px rgba(160, 181, 186, 0.165596),
    0px 1.23656px 1.13351px rgba(160, 181, 186, 0.24);
    border-radius: 4px;
    flex: none;
    order: 1;
    flex-grow: 0;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    width: 161px;
    height: 40px;

    &:hover {
      background: $goldLight;

      svg path {
        stroke: #ffffff;
      }

      .return__text {
        color: $monoOffWhite;
      }
    }

    .action {
      cursor: pointer;
    }

    .return__text {
      // font-family: 'Source Han Sans JP';
      color: #404d50;
      font-size: 14px;
      letter-spacing: 0.03em;
      font-style: normal;
      line-height: 24px;
      font-weight: 500;
    }
  }
}
.template-register-button.disabled {
  pointer-events: none;
  cursor: default;
  background: $monoLight !important;
  box-shadow: 0px 36px 33px rgba(160, 181, 186, 0.0744044), 0px 13.1406px 12.0455px rgba(160, 181, 186, 0.10649), 0px 6.37951px 5.84789px rgba(160, 181, 186, 0.13351), 0px 3.12736px 2.86674px rgba(160, 181, 186, 0.165596), 0px 1.23656px 1.13351px rgba(160, 181, 186, 0.24) !important;

  .v-btn__content {
    span {
      color: $monoMid !important;
    }
    .addregistericon {
      display: none !important;
    }
    .addregistericon_active {
      display: none;
    }
    .addregistericon_disabled {
      display: block;
    }
  }
}
@include desktop {
  .custom-table {
    padding-left: 0 !important;
  }
  .return {
    &__header {
      margin-top: unset;
      margin-left: 40px;
    }
  }
  .return__button-responsive {
    display: none;
  }
  .wrap {
    .wrap__block__header__content {
      .helper-icon {
        &.tooltip {
          position: absolute;
          width: 400px;
          max-width: unset;
        }
      }
    }
  }
}

//custome style
@media screen and (min-width: 1024px) and (max-width: 1150px) {
  .wrap {
    &__block {
      flex-direction: column;

      &__header {
        padding-bottom: 58px;
      }
    }

    //&__status {
    //  padding-right: 0;
    //}
  }
}
</style>